<template>
  <v-list-item class="px-0 py-0">
    <v-list-item-content class="px-0 py-0">
      <v-card-title>
        {{ client.companyName }}
      </v-card-title>
    </v-list-item-content>

    <v-btn
      rounded
      depressed
      outlined
      color="primary"
      class="link-to-product-details mx-4 "
      data-cy-client-button="true"
      :data-cy-client-id="client.id"
      :data-cy-client-company="client.companyName"
      :to="{
        path: `/${locale}/${client.id}/overview`,
        params: { clientId: client.id, locale },
      }"
    >
      {{ $t('partnerView.customerDetails') }}
    </v-btn>
  </v-list-item>
</template>

<script>
export default {
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  computed: {
    locale () {
      return this.$route.params.locale
    }
  }
}
</script>
